import Model from './model';

export class SubmitOptions extends Model {
  install = false;
  financePlan = false;
  terms = false;

  mapForRequest() {
    return this;
  }
}
